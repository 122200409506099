import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DataTable, { createTheme } from 'react-data-table-component'
import { Link, useNavigate } from "react-router-dom";
import '../global'
import Header from './Header.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from './Modal/Modal'
import { Table } from "react-bootstrap";

import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";
import * as XLSX from 'xlsx';
const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${props =>
        props.disappear &&
        css`
      display: block; /* show */
    `}
`;

const DynamicData = () => {
    const [loader, setLoader] = useState(false);
    const [loadermsg, setLoadermsg] = useState('');
    const apiURl=global.apipath;
    const [databases, setDatabases] = useState([]); 
    const [tables, setTables] = useState([]); 
    const [db, setDb] = useState(''); 
    const [tbl, setTbl] = useState(''); 
    const [qry, setQry] = useState(''); 
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [columns, setColumns] = useState([]); 
    const [recs, setData] = useState([]); 
    const navigate = useNavigate();
    useEffect(() => {
        
        if (!localStorage.getItem('user-id')) {
            navigate("/login");
        }
        if (localStorage.getItem('email')) {
            if (localStorage.getItem('email') != 'rizwan@mailsolute.com') {
                navigate("/login");
            }
        }
        getDatabases();
    }, [])
    
   
    const getDatabases = async () => {
        setLoading(true);
        await axios.get(apiURl + 'getDatabases').then(res => {
            setDatabases(res.data)
            setLoading(false);
        }

        )
    }
    const handleDbChange = async (event) => {
        setLoading(true);
        setDb(event.target.value);
        let item = { db: event.target.value };
        setLoading(true);
        axios.post(apiURl + 'GetTables', item).then(response => {
            setTables(response.data);
            setLoading(false);
        })

    }
    const handleTableChange = async (event) => {
        setLoading(true);
        setTbl(event.target.value);
        let item = { db: db,table:event.target.value };
        setLoading(true);
        axios.post(apiURl + 'GetQuery', item).then(response => {
            setQry(response.data[0].qry);
            setLoading(false);
        })

    }
    

    const LoadData = () => {
        setLoading(true);
        let item = { db: db, table: tbl,qry:qry };
        setLoading(true);
        axios.post(apiURl + 'GetDynamicData', item).then(response => {
            var records = response.data.records[0];
            let dtRecs = [];
            for (var i = 0; i < records.length; i++) {
                dtRecs.push(records[i].data);
            }
            setColumns(response.data.columns);
            setData(dtRecs);
            setLoading(false);
        })
    }

    

 
   
   
    
   
  return (
    <div >
        <Header />
          <DarkBackground disappear={loader}>
              {/* <Loader
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#a5d8ff"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />*/}
              <LoadingOverlay
                  active={true}
                  // spinner={<BounceLoader />}
                  spinner={true}
                  text={loadermsg}
              >
                  {/* <p>Some content or children or something.</p> */}
              </LoadingOverlay>
          </DarkBackground>

          <Table striped bordered hover>
              <thead>
                  <tr>
                      <th>Database</th>
                      <th>
                          <select onChange={handleDbChange}>
                              <option value="-1">Select</option>
                              {databases.map(item => (
                                  <option
                                      key={item.name}
                                      value={item.name}
                                  >
                                      {item.name}
                                  </option>
                              ))}
                          </select>
                      </th>
                  </tr>
                  <tr>
                      <th>Table</th>
                      <th>
                          <select onChange={handleTableChange}>
                              <option value="-1">Select</option>
                              {tables.map(item => (
                                  <option
                                      key={item.name}
                                      value={item.name}
                                  >
                                      {item.name}
                                  </option>
                              ))}
                          </select>
                      </th>
                  </tr>
                  <tr>
                      <th></th>
                      <th>
                          <textarea className='w-100'
                              value={qry} onChange={(e) => setQry(e.target.value)}
                              rows={4}
                              cols={4}
                          />
                      </th>
                  </tr>
                  <tr>
                      <th></th>
                      <th>
                          <button className='btn btn-primary backbtn' onClick={() => LoadData()}>Load</button>
                      </th>
                  </tr>
              </thead>
          </Table>

          {
              columns ?
                  <>
                      <Table striped bordered hover>
                          <thead>
                              <tr>
                                  {columns.map(item => (
                                      <th key={item}>
                                          {item}
                                      </th>
                                  ))}
                              </tr>
                          </thead>
                          <tbody>
                              {recs.map(item => (
                                  <tr key={item}>
                                      {item.split(',').map((step) => <td key={step}>{step}</td>)}    
                                  </tr>
                              ))}




                          </tbody>
                      </Table>
                  </> :
                  null
          }
         
    </div>
  )
}

export default DynamicData