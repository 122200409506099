import React,{useState,useEffect} from 'react'
import axios from 'axios'
import DataTable,{ createTheme }  from 'react-data-table-component'
import { Link,useNavigate } from "react-router-dom";
import '../global'
import Header from './Header.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from './Modal/Modal'
import "./AllData.css";

import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${props =>
        props.disappear &&
        css`
      display: block; /* show */
    `}
`;

createTheme("solarized", {
    text: {
        primary: "#268bd2",
        secondary: "#2aa198",
    },
    background: {
        default: "#c7eaf3",
    },
    context: {
        background: "#cb4b16",
        text: "#FFFFFF",
    },
    divider: {
        default: "#073642",
    },
    action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
    },
});

const AllData = () => {
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [loader, setLoader] = useState(false);
    const [loadermsg, setLoadermsg] = useState('');
    const apiURl=global.apipath;
    const [ntype, setNtype] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [description, setDescription] = useState('');
    const [data, setData] = useState([]); 
    const [dataFilter, setDataFilter] = useState([]); 
    const [search, setSearch] = useState(""); 
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        
        if (!localStorage.getItem('user-id')) {
            navigate("/login");
        }
        getData()
    }, [])
    

    useEffect(() =>
    {
        const result=data.filter(rec =>
            {
                return rec.Description.toLowerCase().match(search.toLowerCase());
            }
            )
            setDataFilter(result)
    },[search]
    )

    const getData= async () =>
    {
        setLoading(true);
        let item = { user: localStorage.getItem('user-id') };
        await axios.post(apiURl + 'getstats', item).then(res => {
            let dataset = [];

            res.data.map(el => (
                dataset.push({ id: el.No, name: el.Description })
            ))

            localStorage.setItem("stats", JSON.stringify(dataset));

            setTotalRows(res.data.length);
            setData(res.data)
            setDataFilter(res.data)
            setLoading(false);
        })
        
    }

    
    const copyToClipBoard = (id, description) => {
        {
            var copyStr = '';
            data.map(el => (
                copyStr = copyStr + el.Description + ' ' + el.TotalRecords + '\n'
            ))
            navigator.clipboard.writeText(copyStr);
        }  
    }


    const getExportColumns = (id, description) => {

        let item = { ntype: id};
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Accept": "application/json" },
            body: JSON.stringify(item)
        };
        fetch(apiURl +'getexportcolumns', requestOptions)
            .then(response => response.json())
            .then(data => 
            {
                let items = [];

                for (var i = 0; i < data.length; i++) {
                    //console.log(data[i].COLUMN_NAME);
                    const obj = { 'item': data[i].COLUMN_NAME, 'selected': false };
                    items.push(obj)
                }
                setColumns(items);
                setNtype(id);
                setDescription(description);
                setModalOpen(true);
                });
    }

    const columnsTable = [
        {
            name: '',
            cell: row => <button className='btn btn-primary' onClick={() => { getExportColumns(row.No, row.Description) }} >Export</button>,
            width: "150px" 
        },
        {
            name: '#',
            selector: row => row.No,
            sortable: true,
            width: "100px" 
        },
        {
            name: 'Description',

            cell: row => row.No > 0 && row.No < 8 ? (<Link  className="statsLink" to={`/Charith?ntype=${row.No}`} > { row.Description}</Link >):
                row.No > 7 && row.No < 15 ? (< Link  className="statsLink" to={`/Lyson?ntype=${row.No}`} > { row.Description}</Link >):
                    row.No > 14 && row.No < 22 ? (< Link  className="statsLink" to={`/RGRRefinance?ntype=${row.No}`} > { row.Description}</Link >):
                        row.No > 21 && row.No < 29 ? (< Link  className="statsLink" to={`/RGRSolar?ntype=${row.No}`} > { row.Description}</Link >)
            :row.Description,
        },
        {
            name: 'TotalRecords',
            selector: row => row.TotalRecords,
        },
    ];
    
   
  return (
    <div >
        <Header />
          <ToastContainer />
          <DarkBackground disappear={loader}>
              {/* <Loader
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#a5d8ff"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />*/}
              <LoadingOverlay
                  active={true}
                  // spinner={<BounceLoader />}
                  spinner={true}
                  text={loadermsg}
              >
                  {/* <p>Some content or children or something.</p> */}
              </LoadingOverlay>
          </DarkBackground>
          <button className='btn btn-success clipbtn' onClick={() => { copyToClipBoard() }}>Copy</button>
          {modalOpen && <Modal setOpenModal={setModalOpen} columns={columns} ntype={ntype} description={description} setLoader={setLoader} setLoadermsg={setLoadermsg} fromDate={fromDate} toDate={toDate} />}
          <DataTable className="dataTables_wrapper"
              
              responsive={true}
              fixedHeaderScrollHeight='100%'
            columns={columnsTable}
              data={dataFilter} pagination fixedHeader
              paginationServer
              paginationRowsPerPageOptions={[100, 200, 300]}
            highlightOnHover
            subHeader
            progressPending={loading}
              subHeaderComponent={
                  <>

                      <label>Total Rows: <label>{totalRows} </label></label>
                      <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search here' className='w-25 form-control marginleft2 searchbox' />
                      </>
                
            }
        />
    </div>
  )
}

export default AllData