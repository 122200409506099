import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DataTable, { createTheme } from 'react-data-table-component'
import { Link, useNavigate } from "react-router-dom";
import '../global'
import Header from './Header.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from './Modal/Modal'

import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";
import * as XLSX from 'xlsx';
const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${props =>
        props.disappear &&
        css`
      display: block; /* show */
    `}
`;

const CsvImport = () => {
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [loader, setLoader] = useState(false);
    const [loadermsg, setLoadermsg] = useState('');
    const apiURl=global.apipath;
    const [ntype, setNtype] = useState(0);
    const [description, setDescription] = useState('');
    const [data, setData] = useState([]); 
    const [dataFilter, setDataFilter] = useState([]); 
    const [search, setSearch] = useState(""); 
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        
        if (!localStorage.getItem('user-id')) {
            navigate("/login");
        }
        if (localStorage.getItem('email')) {
            if (localStorage.getItem('email') != 'rizwan@mailsolute.com') {
                navigate("/login");
            }
        }
    }, [])
    
    function isNumeric(value) {
        return /^-?\d+$/.test(value);
    }

    

    // process CSV data
    const processData = dataString => {
        var valid = true;
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        for (let i = 0; i < headers.length; i++) {
            if (headers[i] == '' || headers[i] == undefined || headers[i] == null) {
                valid = false;
            }
            else if (re.test(headers[i])) {
                valid = false;

            }
            else if (isNumeric(headers[i])) {
                valid = false;
            }
            var mydate = new Date(headers[i].toString().replace("AM", "").replace("PM", ""));
            if (mydate != 'Invalid Date') {
                valid = false;
            }
        }
        if (!valid) {
            alert("Invalid header columns.");
        }
        else if (valid) {

            const list = [];
            for (let i = 1; i < 6; i++) {
                const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
                if (headers && row.length == headers.length) {
                    const obj = {};
                    for (let j = 0; j < headers.length; j++) {
                        let d = row[j];
                        if (d.length > 0) {
                            if (d[0] == '"')
                                d = d.substring(1, d.length - 1);
                            if (d[d.length - 1] == '"')
                                d = d.substring(d.length - 2, 1);
                        }
                        if (headers[j]) {
                            obj[headers[j]] = d;
                        }
                    }

                    // remove the blank rows
                    if (Object.values(obj).filter(x => x).length > 0) {
                        list.push(obj);
                    }
                }
            }

            // prepare columns list from headers
            const columns = headers.map(c => ({
                name: c,
                selector: c,
            }));

            setData(list);
            setColumns(columns);
           
        }
        setLoader(false);
        
    }
   

 
    // handle file upload
    const handleFileUpload = (e) => {
        setLoader(true);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
        };
        reader.readAsBinaryString(file);
    };
   
    
   
  return (
    <div >
        <Header />
          <DarkBackground disappear={loader}>
              {/* <Loader
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#a5d8ff"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />*/}
              <LoadingOverlay
                  active={true}
                  // spinner={<BounceLoader />}
                  spinner={true}
                  text={loadermsg}
              >
                  {/* <p>Some content or children or something.</p> */}
              </LoadingOverlay>
          </DarkBackground>
          <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload} /> <br />  <br /> 
          <DataTable pagination highlightOnHover columns={columns} data={data} />
    </div>
  )
}

export default CsvImport