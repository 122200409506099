import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import Header from './Header.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 import '../global'

 import { Formik, Form } from 'formik';
 import { TextField } from './TextField';
 import * as Yup from 'yup';

const Login = () => {
    const validate = Yup.object({
        email: Yup.string()
          .email('Email is invalid')
          .required('Email is required'),
        password: Yup.string()
          .min(6, 'Password must be at least 6 charaters')
          .required('Password is required'),
      })
    
   
    const apiURl=global.apipath;
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('user-id')) {
            navigate("/all-data");
        }
        
    }, [])


return (
    <>
    <Header />
            <ToastContainer />
            <div className='container'> 
            <div className='col-md-4 offset-md-4'>
            <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={validate}
      onSubmit={values => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Accept": "application/json" },
            body: JSON.stringify(values)
        };
        fetch(apiURl+'loginuser', requestOptions)
            .then(response => response.json())
            .then(data => 
            {
                console.log(data);
                    if(data)
                    {
                        if(data[0] == undefined)
                        {
                            toast.error("An error occurred, please try again later.");
                        }
                        else
                        {
                            if(data[0].Id)
                            {
                                localStorage.setItem('user-id', data[0].uid)
                                localStorage.setItem('user-name', data[0].name)
                                localStorage.setItem('email', data[0].email)

                                if (data[0].email == 'rizwan@mailsolute.com') {
                                    data[0].AllData = true;
                                    data[0].Charith = true;
                                    data[0].Logs = true;
                                    data[0].Lyson = true;
                                    data[0].RGRRefinance = true;
                                    data[0].RGRSolar = true;
                                }
                               
                                localStorage.setItem('AllData', data[0].AllData.toString())
                                localStorage.setItem('Charith', data[0].Charith.toString())
                                localStorage.setItem('Logs', data[0].Logs.toString())
                                localStorage.setItem('Lyson', data[0].Lyson.toString())
                                localStorage.setItem('RGRRefinance', data[0].RGRRefinance.toString())
                                localStorage.setItem('RGRSolar', data[0].RGRSolar.toString())
                                if (data[0].AllData == false) {
                                    navigate("/users");
                                }
                                else {
                                    navigate("/all-data");
                                }
                                
                            }
                            else
                            {
                                toast.error("An error occurred, please try again later.");
                            }
                        }
                       
                    }
                    else
                    {
                        toast.error("An error occurred, please try again later.");
                    }
                    
                    
                }
                );
      }}
    >
      {formik => (
        <div>
          <h1 className="my-4 font-weight-bold .display-4">Login</h1>
          <Form>
            <TextField label="Email" name="email" type="email" />
            <TextField label="Password" name="password" type="password" />
            <button className="btn btn-dark mt-3" type="submit">Login</button>
          </Form>
        </div>
      )}
    </Formik>
            </div>
            
            </div>
    
    </>
  )
}

export default Login
