import React,{useState,useEffect} from 'react'
import axios from 'axios'
import DataTable from 'react-data-table-component'
import { useNavigate } from "react-router-dom";
import '../global'
import Header from './Header.js'
import Modal from './Modal/Modal'

import { Row, Col, Button,Form } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${props =>
        props.disappear &&
        css`
      display: block; /* show */
    `}
`;

const Charith = () => {
    const apiURl = global.apipath;

    const [fromDate, setFromDate] = useState("");
    const handleFromChange = e => {
        setFromDate(e.target.value);
    };

    const [toDate, setToDate] = useState("");
    const handleToChange = e => {
        setToDate(e.target.value);
    };


    const sr = window.location.href;
    const params = new URLSearchParams(sr.split('?')[1])
    var ntype = params.get('ntype');
    if (ntype == null) {
        ntype = 7;
    }
    const [loader, setLoader] = useState(false);
    const [loadermsg, setLoadermsg] = useState('');
    const [columns, setColumns] = useState([]);
    const [n, setNtype] = useState(ntype);
    const [description, setDescription] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [data, setData] = useState([]); 
    const [dataFilter, setDataFilter] = useState([]); 
    const [search, setSearch] = useState(""); 
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [pageNum, setPageNum] = useState(1); 
    const [totalRecords, setTotalRecords] = useState(0); 
    const [sortDir, setSortDir] = useState("asc"); 
    const [sortCol, setSortCol] = useState("dt"); 
    const [pageSize, setPageSize] = useState(10); 

    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [datatype, setDatatype] = useState("");
    const [opened, setOpened] = useState(-1);
    const [clicked, setClicked] = useState(0);
    const [charithTypes, setCharithTypes] = useState([]); 

    

    useEffect(() => {
        const sr = window.location.href;
        const params = new URLSearchParams(sr.split('?')[1])
        const ntype = params.get('ntype')
        setNtype(Number(ntype));

        if (!localStorage.getItem('user-id')) {
            navigate("/login");
        }

        if (localStorage.getItem('Charith')) {
            if (localStorage.getItem('Charith') != 'true') {
                navigate("/login");
            }
        }
        getData(1);
        getCharithTypes();
    }, [])

    




    const getData = async page => {
        setLoading(true);
        let item = { description: description, email: localStorage.getItem('email'), user: localStorage.getItem('user-id'), ntype: n, pageNum: page, pageSize: pageSize, search: search, sortDir: sortDir, sortCol: sortCol, from: fromDate, to: toDate, datatype: datatype, opened: opened, clicked: clicked };
        let data = { ntype: n };
        await axios.post(apiURl + 'GetStatInfo', data).then(res => {
            setFromDate(res.data[0].fromDt);
            setToDate(res.data[0].toDt);
            getPageDataFilter(item);
        })
    };

    const getPageDataFilter = async (item) => {
        console.log('getPageDataFilter');
        setLoading(true);
        await axios.post(apiURl + 'getPageDataFilter', item).then(response => {
            if (response.data.err != undefined) {
                if (response.data.err == '403') {
                    setLoading(false);
                    toast.error("Access denied.");
                }
            }
            else {
                setData(response.data);
                if (response.data[0] != undefined) {
                    setTotalRows(response.data[0].totalRec);
                    setLoading(false);
                }
            }
            
        })
    }



    const ExportData = () => {
        {
            let item = { description: description, email: localStorage.getItem('email'), user: localStorage.getItem('user-id'), ntype: n };
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "Accept": "application/json" },
                body: JSON.stringify(item)
            };
            fetch(apiURl + 'getexportcolumns', requestOptions)
                .then(response => response.json())
                .then(data => {

                    if (data.err != undefined) {
                        if (data.err == '403') {
                            toast.error("Access denied.");
                        }
                    }
                    else {
                        let items = [];

                        for (var i = 0; i < data.length; i++) {
                            //console.log(data[i].COLUMN_NAME);
                            const obj = { 'item': data[i].COLUMN_NAME, 'selected': false };
                            items.push(obj)
                        }
                        setColumns(items);
                        setModalOpen(true);
                    }
                    
                });
        }
    }
    const getCharithTypes = async () => {
        await axios.get(apiURl + 'getCharithTypes').then(res => {
            setCharithTypes(res.data)
        }

        )
    }

    const FilterByDate = async () => {
        let item = { description: description, email: localStorage.getItem('email'),user: localStorage.getItem('user-id'), ntype: n, pageNum: pageNum, pageSize: pageSize, search: search, sortDir: sortDir, sortCol: sortCol, from: fromDate, to: toDate, datatype: datatype, opened: opened, clicked: clicked };
        getPageDataFilter(item);
        
    }
    
    

    const handlePageChange = page => {
        getData(page);
        setPageNum(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        
        setPageSize(newPerPage);
        let item = { description: description, email: localStorage.getItem('email'),user: localStorage.getItem('user-id'), ntype: n, pageNum: page, pageSize: newPerPage, search: search, sortDir: sortDir, sortCol: sortCol, from: fromDate, to: toDate, datatype: datatype, opened: opened, clicked: clicked };
        await getPageDataFilter(item);
    };

    const handleSort = async (column, sortDirection) => {
        
        if (column.selector != undefined) {
            var col = column.selector.toString();
            col = col.replace('row => row.', '');
            col = col.replace('function(e) { return e.', '');
            col = col.replace('function(e)', '');
            col = col.replace('{', '');
            col = col.replace('return e.', '');
            
            col = col.replace('}', '');
            setSortCol(col);
            setSortDir(sortDirection);

            let item = { description: description, email: localStorage.getItem('email'),user: localStorage.getItem('user-id'), ntype: n, pageNum: pageNum, pageSize: pageSize, search: search, sortDir: sortDirection, sortCol: col, from: fromDate, to: toDate, datatype: datatype, opened: opened, clicked: clicked };
            await getPageDataFilter(item);

        }
    };
    const updateSearch = async (s) => {
        setSearch(s);


        let item = { description: description, email: localStorage.getItem('email'),user: localStorage.getItem('user-id'), ntype: n, pageNum: pageNum, pageSize: pageSize, search: s, sortDir: sortDir, sortCol: sortCol, from: fromDate, to: toDate, datatype: datatype, opened: opened, clicked: clicked };
        await getPageDataFilter(item);
    };


    
    const handleDataTypeChange = async (event) => {
        setDatatype(event.target.value);
        let item = { description: description, email: localStorage.getItem('email'),user: localStorage.getItem('user-id'), ntype: n, pageNum: pageNum, pageSize: pageSize, search: search, sortDir: sortDir, sortCol: sortCol, from: fromDate, to: toDate, datatype: event.target.value, opened: opened, clicked: clicked };
        await getPageDataFilter(item);

    }

    const handleOpenedChange = async (event) => {
        setOpened(event.target.value);
        let item = { description: description, email: localStorage.getItem('email'),user: localStorage.getItem('user-id'), ntype: n, pageNum: pageNum, pageSize: pageSize, search: search, sortDir: sortDir, sortCol: sortCol, from: fromDate, to: toDate, datatype: datatype, opened: event.target.value, clicked: clicked };
        await getPageDataFilter(item);

    }

    const handleClickedChange = async (event) => {
        setClicked(event.target.value);
        let item = { description: description, email: localStorage.getItem('email'),user: localStorage.getItem('user-id'), ntype: n, pageNum: pageNum, pageSize: pageSize, search: search, sortDir: sortDir, sortCol: sortCol, from: fromDate, to: toDate, datatype: datatype, opened: opened, clicked: event.target.value };
        await getPageDataFilter(item);

    }

    const columnsTable = [
        {
            name: 'Email',
            selector: row => row.Email,
            sortable:true
        },
        {
            name: 'Fname',
            selector: row => row.Fname,
            sortable:true
        },
        {
            name: 'Lname',
            selector: row => row.Lname,
            sortable:true
        },
        {
            name: 'City',
            selector: row => row.City,
            sortable:true
        },
        {
            name: 'State',
            selector: row => row.State,
            sortable:true
        },
        {
            name: 'Zip',
            selector: row => row.Zip,
            sortable:true
        },
        {
            name: 'Address',
            selector: row => row.Address,
            sortable:true
        },
        {
            name: 'UOpened',
            selector: row => row.UOpened,
            sortable:true
        },
        {
            name: 'Opened',
            selector: row => row.Opened,
            sortable:true
        },
        {
            name: 'Clicked',
            selector: row => row.Clicked,
            sortable:true
        },
        {
            name: 'Unsub',
            selector: row => row.Unsub,
            sortable:true
        },
        {
            name: 'Bounced',
            selector: row => row.Bounced,
            sortable:true
        },
        {
            name: 'Spam',
            selector: row => row.Spam,
            sortable:true
        },
        {
            name: 'UClicked',
            selector: row => row.UClicked,
            sortable:true
        },
        {
            name: 'DateInserted',
            selector: row => row.dt,
            sortable:true
        },
    ];
   
   
  return (
      <div >
          <ToastContainer />
          <Header />
          <button className='btn btn-primary backbtn' onClick={() => navigate(-1)}>Back</button>
          <button className='btn btn-primary backbtn exportbtn' onClick={() => ExportData()}>Export</button>
          <br />
          <div className='container margintop20' >
              <Col xs={4} md={3} className='textleft filterdv'>
                      <span>From</span>
                      <input
                          name="FromDate"
                          type="date"
                          size="sm"
                          className="form-control"
                          onChange={handleFromChange}
                          value={fromDate}
                      />
                  </Col>
              <Col xs={4} md={3} className='textleft filterdv'>
                      <span>To</span>
                      <input
                          name="ToDate"
                          type="date"
                          size="sm"
                          className="form-control"
                          onChange={handleToChange}
                          value={toDate}
                      />
                  </Col>
                  <Col xs={4} md={3}  className='filterdv'>
                  <br />

                  <Button onClick={FilterByDate} className="margintop0" style={{  width: "100%" }}>
                      Filter
                  </Button>
                  
              </Col>
              <br />
              <Col xs={4} md={3} className='textleft filterdv'>
                  <span>DataType</span>
                 
                  <Form.Select onChange={handleDataTypeChange}>
                      <option value="">Select</option>
                      {charithTypes.map(item => (
                          <option
                              key={item.type}
                              value={item.type}
                          >
                              {item.type}
                          </option>
                      ))}
                  </Form.Select>
              </Col>
              <Col xs={4} md={3} className='textleft filterdv'>
                  <span>Opened</span>
                  <Form.Select onChange={handleOpenedChange}>
                      <option value="">Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                  </Form.Select>
              </Col>
              <Col xs={4} md={3} className='textleft filterdv'>
                  <span>Clicked</span>
                  <Form.Select onChange={handleClickedChange}>
                      <option value="">Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                  </Form.Select>
              </Col>
              </div>
          

           

          {modalOpen && <Modal setOpenModal={setModalOpen} columns={columns} ntype={n} description={description} setLoader={setLoader} setLoadermsg={setLoadermsg} fromDate={fromDate} toDate={toDate} datatype={datatype} opened={opened} clicked={clicked} />}
          <DarkBackground disappear={loader}>
              {/* <Loader
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#a5d8ff"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />*/}
              <LoadingOverlay
                  active={true}
                  // spinner={<BounceLoader />}
                  spinner={true}
                  text={loadermsg}
              >
                  {/* <p>Some content or children or something.</p> */}
              </LoadingOverlay>
          </DarkBackground>
          <DataTable
              columns={columnsTable}
              data={data}
              progressPending={loading}
              pagination
              paginationServer
              paginationRowsPerPageOptions={[10, 10000]}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationTotalRows={totalRows}
              onChangePage={handlePageChange}
              sortServer
              onSort={handleSort}
              subHeader
              subHeaderComponent={
                  <input type="text" value={search} onChange={(e) => updateSearch(e.target.value)} placeholder='Search here' className='w-25 form-control' />
              }
          />
    </div>
  )
}

export default Charith