import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import Header from './Header.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table, Col, Button } from "react-bootstrap";
import '../global'
import axios from 'axios'
import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${props =>
        props.disappear &&
        css`
      display: block; /* show */
    `}
`;

const Summary = () => {
    
    const [domain, setDomain] = useState("");
    const [loader, setLoader] = useState(false);
    const [loadermsg, setLoadermsg] = useState('loading...');

    const [fromDate, setFromDate] = useState("");
    const handleFromChange = e => {
        setFromDate(e.target.value);
    };

    const [toDate, setToDate] = useState("");
    const handleToChange = e => {
        setToDate(e.target.value);
    };


    const apiURl = global.apipath;
    const [data, setData] = useState([])
    const navigate = useNavigate();
    useEffect(() => {

        if (!localStorage.getItem('user-id')) {
            navigate("/login");
        }

        if (localStorage.getItem('Charith')) {
            if (localStorage.getItem('Charith') != 'true') {
                navigate("/login");
            }
        }
    }, [])	


    const FilterByDate = async () => {
        console.log(data);
        let item = { from: fromDate, to: toDate, domain: domain };
        getPageDataFilter(item);
    }
    const handleDomain = async (event) => {
        setDomain(event.target.value);
    }

    const getPageDataFilter = async (item) => {
        if ((item.from != '' && item.to != '') || item.domain != '') {
            setLoader(true);
            await axios.post(apiURl + 'getCharithStats', item).then(response => {
                setData(response.data[0]);
                setLoader(false);
            })
        }
    }



    return (
        <>
            <Header />
            <ToastContainer />
            <DarkBackground disappear={loader}>
                {/* <Loader
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#a5d8ff"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />*/}
                <LoadingOverlay
                    active={true}
                    // spinner={<BounceLoader />}
                    spinner={true}
                    text={loadermsg}
                >
                    {/* <p>Some content or children or something.</p> */}
                </LoadingOverlay>
            </DarkBackground>
            <div className='container margintop20 marginbottom20' >
                <Col xs={4} md={3} className='textleft filterdv'>
                    <span>From</span>
                    <input
                        name="FromDate"
                        type="date"
                        size="sm"
                        className="form-control"
                        onChange={handleFromChange}
                        value={fromDate}
                    />
                </Col>
                <Col xs={4} md={3} className='textleft filterdv'>
                    <span>To</span>
                    <input
                        name="ToDate"
                        type="date"
                        size="sm"
                        className="form-control"
                        onChange={handleToChange}
                        value={toDate}
                    />
                </Col>
                <Col xs={4} md={3} className='filterdv'>
                    <br />

                    <Button onClick={FilterByDate} className="margintop0" style={{ width: "100%" }}>
                        Filter
                  </Button>

                </Col>
                <br />
                <Col xs={4} md={3} className='textleft filterdv'>
                    <span>Domain</span>
                    <input type="text" value={domain} onKeyDown={handleDomain} onChange={handleDomain} className='w-100 form-control' />
                </Col>
                <br />


            </div>
            <br />
            <br />
            <br />
            				
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Total</th>
                        <th>Opened</th>
                        <th>% Opened</th>
                        <th>Clickers</th>
                        <th>% Clickers</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 && (
                        data.map(dt => (
                            <tr key={dt.Total} >
                                <td>{dt.Total}</td>
                                <td>{dt.Opened}</td>
                                <td>{dt.OpenedPercentage}</td>
                                <td>{dt.clickers}</td>
                                <td>{dt.ClickersPercentage}</td>
                            </tr> 
                        ))
                    )}
                </tbody>
            </Table>
        </>
    )
}

export default Summary
