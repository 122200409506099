import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
const Protected = (props) => {
    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem('user-id')) {
            navigate("/login");
        }
    }, [])

    let CMP=props.cmp;
    return (
        <>
            <CMP />
        </>
    )
}

export default Protected