import logo from './logo.svg';
import './App.css';
import { Button } from "react-bootstrap";

import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom';
import Login from './components/Login'
import Register from './components/Register'
import AddProduct from './components/AddProduct'
import User from './components/User'
import AllData from './components/AllData'
import RGRRefinance from './components/RGRRefinance'
import Charith from './components/Charith'
import RGRSolar from './components/RGRSolar'
import Lyson from './components/Lyson'
import Logs from './components/Logs'
import AccessRights from './components/AccessRights'
import Protected  from './Protected'
import ReactDatatable from './components/ReactDatatable'
import CsvImport from './components/CsvImport'
import DynamicData from './components/DynamicData'
import Summary from './components/Summary'
import CharithClickers from './components/CharithClickers'

function App() {
  return (
      <div className="App">
          <HashRouter hashType="hashbang">

  <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      {/*<Route path="/add-product" element={<Protected cmp={AddProduct} />} />*/}
                  <Route path="/users" element={<Protected cmp={User} />} />
                  <Route path="/all-data" element={<Protected cmp={AllData} />} />
                  <Route path="/RGRRefinance" element={<Protected cmp={RGRRefinance} />} />
                  <Route path="/RGRSolar" element={<Protected cmp={RGRSolar} />} />
                  <Route path="/Charith" element={<Protected cmp={Charith} />} />
                  <Route path="/Lyson" element={<Protected cmp={Lyson} />} />
                  <Route path="/logs" element={<Protected cmp={Logs} />} />
                  <Route path="/AccessRights" element={<Protected cmp={AccessRights} />} />
                  <Route path="/ReactDatatable" element={<Protected cmp={ReactDatatable} />} />
                  <Route path="/CsvImport" element={<Protected cmp={CsvImport} />} />
                  <Route path="/DynamicData" element={<Protected cmp={DynamicData} />} />
                  <Route path="/Summary" element={<Protected cmp={Summary} />} />
                  <Route path="/charith-clickers" element={<Protected cmp={CharithClickers} />} />

                  
                  
      <Route path="/register" element={<Register />} />
    </Routes>

          </HashRouter>
    </div>
  );
}

export default App;
