import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import Header from './Header.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../global'
const Register = () => {
    const apiURl=global.apipath;
    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem('user-id')) {
            navigate("/login");
        }
    }, [])
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


   

    const signUp = () => {
        let item = { name, email, password }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Accept": "application/json" },
            body: JSON.stringify(item)
        };
        fetch(apiURl+'adduser', requestOptions)
            .then(response => response.json())
            .then(data => 
                {
                    if(data.message == '1')
                    {
                        toast.success("Your account has been created!");
                        
                        setTimeout(function() { 
                            navigate("/login");
                         }, 3000);
                    }
                    else
                    {
                        toast.error("An error occurred, please try again later.");
                    }
                    
                });


    }




    return (
        <>
            <Header />
            <ToastContainer />
            <div className='col-sm-6 offset-sm-3'>
                <h1>User Sign Up</h1>
                <input type="text" placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} className='form-control' />
                <br />
                <br />
                <input type="text" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} className='form-control' />
                <br />
                <input type="password" placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} className='form-control' />
                <br />
                <button onClick={signUp} className='btn btn-primary'>Sign Up</button>
            </div>
        </>
    )
}

export default Register
