import React,{useState,useEffect} from 'react'
import axios from 'axios'
import DataTable from 'react-data-table-component'
import '../global'
import Header from './Header.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from './Modal/Modal'
const ReactDatatable = () => {
    const apiURl=global.apipath;
    const [ntype, setNtype] = useState(0);
    const [description, seDescription] = useState('');
    const [data, setData] = useState([]); 
    const [dataFilter, setDataFilter] = useState([]); 
    const [search, setSearch] = useState(""); 
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    useEffect(() =>
    {
        getData()
    },[]
    )

    useEffect(() =>
    {
        const result=data.filter(rec =>
            {
                return rec.Description.toLowerCase().match(search.toLowerCase());
            }
            )
            setDataFilter(result)
    },[search]
    )

    const getData= async () =>
    {
        setLoading(true);
        await axios.get(apiURl +'getstats').then(res => 
            {
                setData(res.data)
                setDataFilter(res.data)
                setLoading(false);
            }
        
        )
    }

    const handleSort = (column, sortDirection) => {
        
		// simulate server sort
		console.log(column, sortDirection);
		setLoading(true);
        //setDataFilter(orderBy(data, column.sortField, sortDirection));
		
	};


    const getExportColumns = (id, description) => {

        let item = { ntype: id};
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Accept": "application/json" },
            body: JSON.stringify(item)
        };
        fetch(apiURl +'getexportcolumns', requestOptions)
            .then(response => response.json())
            .then(data => 
            {
                let items = [];

                for (var i = 0; i < data.length; i++) {
                    //console.log(data[i].COLUMN_NAME);
                    const obj = { 'item': data[i].COLUMN_NAME, 'selected': false };
                    items.push(obj)
                }
                setColumns(items);
                setNtype(id);
                seDescription(description);
                setModalOpen(true);
                });
    }

    const columnsTable = [
        {
            name: '',
            cell : row => <button className='btn btn-primary' onClick={() => { getExportColumns(row.No, row.Description) }} >Export</button>,
            
        },
        {
            name: '#',
            selector: row => row.No,
            sortable:true
        },
        {
            name: 'Description',
            selector: row => row.Description,
        },
        {
            name: 'TotalRecords',
            selector: row => row.TotalRecords,
        },
    ];
    
   
  return (
    <div >
        <Header />
            <ToastContainer />
            {modalOpen && <Modal setOpenModal={setModalOpen} columns={columns} ntype={ntype} description={description} />}
       <DataTable
            columns={columnsTable}
            data={dataFilter} pagination fixedHeader
            fixedHeaderScrollHeight='450px'
            selectableRows
            highlightOnHover
            subHeader
            progressPending={loading}
            // sortServer
			// onSort={handleSort}
            subHeaderComponent={
                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search here' className='w-25 form-control' />
            }
        />
    </div>
  )
}

export default ReactDatatable