import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DataTable from 'react-data-table-component'
import { useNavigate } from "react-router-dom";
import '../global'
import Header from './Header.js'

import Modal from './Modal/Modal'

import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";

import { Row, Col, Button } from "react-bootstrap";

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${props =>
        props.disappear &&
        css`
      display: block; /* show */
    `}
`;

const Logs = () => {
    

    const apiURl = global.apipath;
    const [loader, setLoader] = useState(false);
    const [loadermsg, setLoadermsg] = useState('');
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [pageNum, setPageNum] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [sortDir, setSortDir] = useState("desc");
    const [sortCol, setSortCol] = useState("Dated"); 
    const [pageSize, setPageSize] = useState(100);

    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(100);

    useEffect(() => {
        if (!localStorage.getItem('user-id')) {
            navigate("/login");
        }
        else if (localStorage.getItem('Logs')) {
            if (localStorage.getItem('Logs') != 'true') {
                navigate("/all-data");
            }
        }
        else {
            getData(1)
        }
    }, [])






    const getData = async page => {

        let item = {  pageNum: page, pageSize: pageSize};
        setLoading(true);
        await axios.post(apiURl + 'GetLogs', item).then(response => {
            setData(response.data);
            if (response.data[0] != undefined) {
                setTotalRows(response.data[0].totalRec);
            }
            setLoading(false);
        })
    };

    const handlePageChange = page => {
        getData(page);
        setPageNum(page);
    };
   

    const columnsTable = [
        {
            name: 'Activity',
            selector: row => row.Activity,
            sortable: false
        }
    ];

    return (
        <div >
            <Header />
            <button className='btn btn-primary backbtn' onClick={() => navigate(-1)}>Back</button>
            <br />
            <DarkBackground disappear={loader}>
                {/* <Loader
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#a5d8ff"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />*/}
                <LoadingOverlay
                    active={true}
                    // spinner={<BounceLoader />}
                    spinner={true}
                    text={loadermsg}
                >
                    {/* <p>Some content or children or something.</p> */}
                </LoadingOverlay>
            </DarkBackground>
            <DataTable
                columns={columnsTable}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationRowsPerPageOptions={[100]}
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
            />
        </div>
    )
}

export default Logs