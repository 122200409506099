import React, {  useEffect } from 'react'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { Link,useNavigate } from 'react-router-dom';
const Header = () => {
    const navigate = useNavigate();
    function Logout()
    {
        localStorage.clear()
        navigate("/login");
    }

    const name = localStorage.getItem('user-name');
    return (
        <div>
            <Navbar bg="light" expand="lg">
                <Container className="headerdiv">
                    <Navbar.Brand to="/">
                        <img
                            src="https://csezone.com/wp-content/uploads/2020/10/logo.png"
                            width="150"
                            className="d-inline-block align-top"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">

                       

                        <Nav className="me-auto">
                            {
                                localStorage.getItem('Charith') == 'true' ?
                                    <>
                                        <Link className='nav-link' to="/summary">Summary</Link>
                                        <Link className='nav-link' to="/charith-clickers">Charith Clickers</Link>
                                        
                                    </> : null
                            }
                            {
                                localStorage.getItem('AllData') == 'true' ?
                                    <>
                                        <Link className='nav-link' to="/all-data">All Data</Link>
                                    </> : null
                            }
                            {
                                localStorage.getItem('Logs') == 'true' ?
                                    <>
                                        <Link className='nav-link' to="/logs">Logs</Link>
                                    </> : null
                            }
                            {
                                localStorage.getItem('email') == 'rizwan@mailsolute.com' ?
                                    <>
                                        <Link className='nav-link' to="/users">Users</Link>

                                    </> :
                                    null
                            }
                            {
                                localStorage.getItem('email') == 'rizwan@mailsolute.com' ?
                                    <>
                                        <Link className='nav-link' to="/csvimport">Csv Import</Link>

                                    </> :
                                    null
                            }
                            {
                                localStorage.getItem('email') == 'rizwan@mailsolute.com' ?
                                    <>
                                        <Link className='nav-link' to="/dynamicdata">Dynamic Data</Link>

                                    </> :
                                    null
                            }

                            
                            

                            {
                                localStorage.getItem('email') == 'rizwan@mailsolute.com' ?
                                    <>
                                        <Link className='nav-link' to="/AccessRights">Access Rights</Link>
                                    </>:null
                            }

                            {
                                localStorage.getItem('email') ?
                                     null:
                                    <>
                                            <Link className='nav-link' to="/login">Login</Link>
                                    </> 
                                    
                            }
                               
                            
                        </Nav>
                            {
                                localStorage.getItem('user-id') ?
                                    <>
                                    <Nav className='nav navbar-nav navbar-right'>
                                        <NavDropdown className='logoutMenu' title={name} id="basic-nav-dropdown">
                                            <NavDropdown.Item onClick={Logout}>Logout</NavDropdown.Item>
                                            </NavDropdown>
                                            </Nav>
                                    </> : null

                            }

                           
                        
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Header