import React, { useState, useEffect } from 'react'
import "./Modal.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../global'

function Modal({ setOpenModal, columns, ntype, description, setLoader, setLoadermsg, fromDate, toDate, datatype, opened, clicked }) {
    const apiURl = global.apipath;
    useEffect(() => {
        setColumnsExport(columns);
        setLoadermsg('Exporting Csv...');
        
    }, [])
    const [columnsExport, setColumnsExport] = useState([]);
    const handleCheckboxChange = (e) => {
        let isChecked = e.target.checked;
        let name = e.target.name;

        let updatedData = columnsExport;
        for (var i = 0; i < updatedData.length; i++) {
            if (updatedData[i].item == name) {
                updatedData[i].selected = isChecked;
            }
        }
        setColumnsExport(updatedData);
    };
    const exportCSV = (e) => {
        var selected = '';
        for (var i = 0; i < columnsExport.length; i++) {
            if (columnsExport[i].selected) {
                selected = selected+columnsExport[i].item+','
            }
        }
        if (selected == '') {
            toast.error("An error occurred, please try again later.");
        }
        else {
            let item = { ntype: ntype, selected: selected, description: description, user: localStorage.getItem('user-id'), email: localStorage.getItem('email'), from: fromDate, to: toDate, datatype: datatype, opened: opened, clicked: clicked };
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "Accept": "application/json" },
                body: JSON.stringify(item)
            };
            setLoader(true);
            fetch(apiURl + 'exportcsv', requestOptions)
                .then(response => response.json())
                .then(data => {
                    const url = window.URL.createObjectURL(new Blob(data, { type: 'text/csv;encoding:utf-8' }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'AllData.csv')
                    document.body.appendChild(link)
                    setLoader(false);
                    link.click()
                    setOpenModal(false);
                });
        }
        
    };
  return (
      <div className="modalBackground">
          <ToastContainer />
          
      <div className="modalContainer">
        <div className="titleCloseBtn">
          
        </div>
              <div className="title">
                  <h5 className='h5'>Please select columns for export?</h5>
        </div>
        <div className="body">
                  {
                      columns.map((option, index) => {
                          return (
                              <label key={option.item} className='label'><input key={option.item} value={option.selected } name={option.item} onChange={(e) => handleCheckboxChange(e)} type="checkbox" />{option.item}</label>
                          );
                      })
                  }
        </div>
        <div className="footer">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
            id="cancelBtn"
          >
                      Cancel
          </button>
                  <button onClick={(e) => exportCSV(e) }>Export</button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
