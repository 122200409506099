import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import Header from './Header.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table } from "react-bootstrap";
import '../global'

import { Formik, Form } from 'formik';
import { TextField } from './TextField';
import * as Yup from 'yup';

const Register = () => {
    const validate = Yup.object({
        name: Yup.string()
          .max(15, 'Must be 15 characters or less')
          .required('Required'),
        email: Yup.string()
          .email('Email is invalid')
          .required('Email is required'),
        password: Yup.string()
          .min(6, 'Password must be at least 6 charaters')
          .required('Password is required'),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Password must match')
          .required('Confirm password is required'),
      })



    const apiURl=global.apipath;
    const [data, setData] = useState([])
    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem('user-id')) {
            navigate("/login");
        }
        if (localStorage.getItem('email')) {
            if (localStorage.getItem('email') != 'rizwan@mailsolute.com') {
                navigate("/login");
            }
        }
        fetchData()
    }, [])

    const fetchData = async () => {
        const response = await fetch(apiURl+'getusers')
        const data = await response.json()
        setData(data)
      }

    

    const deleteUser = (id) => {

        let item = { id: id};
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Accept": "application/json" },
            body: JSON.stringify(item)
        };
        fetch(apiURl+'deleteuser', requestOptions)
            .then(response => response.json())
            .then(data => 
                {
                    if(data.message == '1')
                    {
                        toast.success("Account has been deleted!");
                        fetchData()
                    }
                    else
                    {
                        toast.error("An error occurred, please try again later.");
                    }
                    
                });
    }



    return (
        <>
            <Header />
            <ToastContainer />
            <div className='container'> 
            <div className='col-md-6 offset-md-3'>
            <Formik
      initialValues={{
          name:'',
        email: '',
        password: '',
        confirmPassword: ''
      }}
      validationSchema={validate}
      onSubmit={values => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Accept": "application/json" },
            body: JSON.stringify(values)
        };
        fetch(apiURl+'adduser', requestOptions)
            .then(response => response.json())
            .then(data => 
                {
                    if(data.message == '1')
                    {
                        toast.success("Account has been created!");
                        fetchData()
                    }
                    else
                    {
                        toast.error("An error occurred, please try again later.");
                    }
                    
                });
      }}
    >
      {formik => (
        <div>
          <h1 className="my-4 font-weight-bold .display-4">Add User</h1>
          <Form>
          <TextField label="Name" name="name" type="text" />
          <TextField label="Email" name="email" type="email" />
            <TextField label="password" name="password" type="password" />
            <TextField label="Confirm Password" name="confirmPassword" type="password" />
            <button className="btn btn-dark mt-3" type="submit">Add</button>
          </Form>
        </div>
      )}
    </Formik>
            </div>
            
            </div>
            <br></br>
            <Table striped bordered hover>
       <thead>
         <tr>
           <th>Name</th>
           <th>Email</th>
           <th>UserType</th>
           <th></th>
         </tr>
       </thead>
       <tbody>
      {data.length > 0 && (
          data.map(dt =>(
              Number(localStorage.getItem('user-id'))  != dt.Id ?
            <tr key={dt.Id}>
              <td>{dt.name}</td>
              <td>{dt.email}</td>
              <td>{dt.UserType}</td>
              <td><button onClick={() => { deleteUser(dt.Id) }}  className='btn btn-danger'>Delete</button></td>
            </tr> :null
          ))
      )}
      </tbody>
</Table>
        </>
    )
}

export default Register
